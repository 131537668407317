<template>
  <vx-card :title="title">
    
          <customer-component></customer-component>
      
  </vx-card>
</template>
<script>
import CustomerComponent from "./customer";
export default {
  components: {
    CustomerComponent,
  },
  data() {
    return {
      active: 0,
      title: "Customer Approval"
    };
  },
  mounted() {},
  methods: {
   
  }
};
</script>