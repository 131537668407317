<template>
  <div class="vx-col w-11/12">
    <!-- <perfect-scrollbar> -->
    <div class="vx-col w-11/12 ml-auto mr-auto">
      <span>
        <b>Approve Customer</b>
      </span>
      <div class="vx-row mb-6" v-if="customerId != null">
        <div class="vx-col w-full">
          <vs-input disabled class="w-full" label="Code" v-model="data.code" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Name"
            v-validate="'required'"
            name="Name"
            v-model="data.name"
          />
          <span class="text-danger text-sm" v-show="errors.has('Name')">{{
            errors.first("Name")
          }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Supplier</label>
          <supplier
            :id="data.supplierId"
            @data="setSupplierId"
          ></supplier>
          <span
            class="text-danger text-sm"
            v-show="errors.has('Supplier')"
            >{{ errors.first("Supplier") }}</span
          >
          
        </div>
      </div>
      
      <address-component
        :data="data.address"
        @data="setDataAddress"
      ></address-component>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Contact Name"
            v-model="data.contactName"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Phone"
            v-model="data.phone"
            v-validate="'required|numeric'"
            name="Phone"
          />
          <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
            errors.first("Phone")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Mobile"
            v-model="data.mobile"
            v-validate="'numeric'"
            name="Mobile"
          />
          <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{
            errors.first("Mobile")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Email"
            v-model="data.email"
            v-validate="'email'"
            name="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('Email')">{{
            errors.first("Email")
          }}</span>
        </div>
      </div>
     
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
    <!-- </perfect-scrollbar> -->
  </div>
</template>
<script>
import CountryComponent from "@/components/master/Country";
import ProvinceComponent from "@/components/master/Province";
import CityComponent from "@/components/master/City";
import DistrictComponent from "@/components/master/District";
import SubdistrictComponent from "@/components/master/Subdistrict";
import PerfectScrollbar from "vue-perfect-scrollbar";
import PaymentMethod from "./form-component/PaymentMethod";
import BussinessPartner from "./form-component/BussinessPartner";
import Status from "./form-component/Status";
import Supplier from "./form-component/Supplier";
import AddressComponent from "./form-component/Address";

export default {
  components: {
    CountryComponent,
    ProvinceComponent,
    CityComponent,
    DistrictComponent,
    SubdistrictComponent,
    PerfectScrollbar,
    PaymentMethod,
    BussinessPartner,
    Status,
    Supplier,
    AddressComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer-approval",
        data: {
          parentID: null,
          code: "",
          name: "",
          contactName: "",
          phone: "",
          mobile: "",
          email: "",
          supplierId: null,
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            provinceName: "",
            cityName: "",
            districtName: "",
            subdistrictName: "",
          },
          customer_territory: []
        },
        city: {
          id: null,
          provinceId: null,
          name: "",
        },
          supplier: {
            selected: null,
            options: [],
          },
      };
    },
    inputCity(value) {
      this.city.id = value.id;
      this.city.provinceId = value.province_id;
      this.city.name = value.name;
      this.city.code = value.code;
      this.data.city = value.name;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        // dimatiin, karena NOO tidak wajib milih supplier
        // if (!this.data.supplierId) {
        //   this.errors.add({
        //     field: "Supplier",
        //     msg: "The supplier field is required",
        //   });
        //   result = false;
        // }
        if (result) {
          if (this.customerId) {
            this.putData();
          }
        }
      });
    },
    paramData() {
      
      return {
        //parent_id: this.data.parentId,
        code: this.data.code,
        name: this.data.name,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        contact_name: this.data.contactName,
        mobile: this.data.mobile,
        phone: this.data.phone,
        email: this.data.email,
        
        supplier_id: this.data.supplierId,
        territory_ids: this.data.customer_territory
      };
    },
    
    putData() {
      this.$vs.loading();
      this.$http
        .put(this.baseUrl + "/" + this.customerId, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerId) {
        this.$http
          .get(this.baseUrl + "/" + this.customerId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.parentId = resp.data.parent_id;
              this.data.code = resp.data.code;
              this.data.name = resp.data.name;
              // this.data.address = resp.data.address;
              this.data.contactName = resp.data.contact_name;
              this.data.phone = resp.data.phone;
              this.data.mobile = resp.data.mobile;
              this.data.email = resp.data.email;
             
              if (resp.data.status) {
                this.data.status = resp.data.status;
              }
              // this.city.name = resp.data.city;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;
              this.data.supplierId = resp.data.supplier_id;

              for (var i = 0; i < resp.data.customer_territory.length; i++) {
                this.data.customer_territory.push(resp.data.customer_territory[i].territory_id)
              }

            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
          });
      }
    },
    setParentId(val) {
      this.data.parentId = val;
    },
    setStatus(val) {
      this.data.status = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
    
    setSupplierId(val) {
      this.data.supplierId = val;
    },
  },
  mounted() {
    if (this.customerId) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    customerId(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
.ps {
  height: 800px;
}
</style>
